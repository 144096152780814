<footer class="footer pt-5 ">
  <div class="container">
    <div class="footer-column">
      <h3>Onesb Bangalore :</h3>
      <p style="font-size: 15px;">ONESB - One School of Business was founded with a quest to educate and prepare a whole new generation of students to excel in the technologically advanced, globally complex, dynamic, fast paced world of business situated in Bangalore - One of the world’s top start-up hubs</p>
      
    </div>
    
    <div class="footer-column">
      <h3>Contact Us</h3>
      <p>#23/1, Nagawara, Bangalore - 560 045, Karnataka, India</p>
      <p>Admission Hotline: <a href="tel:+919035065340">+91 9035065340</a></p>
<p>Toll Free Number: <a href="tel:+919035065340">+91 9035065340</a></p>

      
      <div class="footer-column" style="margin-top: 40px;">
        <iframe 
        src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d124383.27780966228!2d77.53822224474132!3d13.037108477775316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3bae17dacaaaa051%3A0x4e87ce994489e8a9!2sWard%20No.23%2C%20BBMP%2C%2023%2F1%2C%20Govindapura%2C%20Nagawara%2C%20Bengaluru%2C%20Karnataka%20560045!3m2!1d13.0371355!2d77.6206255!5e0!3m2!1sen!2sin!4v1741851684576!5m2!1sen!2sin" 
        width="200" height="150" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0">
    </iframe>
      </div>
       
      <div class="social-icons" style="margin-top: 50px;">
     
        <a href="https://www.facebook.com/ONESBBNGLR"><img height="20px" width="20px" src="https://img.icons8.com/ios-glyphs/30/FFFFFF/facebook.png"
            alt="Facebook"></a>
        <a href="https://www.linkedin.com/in/one-school-of-business/"><img height="20px" width="20px" src="https://img.icons8.com/ios-glyphs/30/FFFFFF/linkedin.png"
            alt="LinkedIn"></a>
        <a href="https://www.youtube.com/@onesb1"><img height="20px" width="20px" src="https://img.icons8.com/ios-glyphs/30/FFFFFF/youtube.png"
            alt="YouTube"></a>
        <a href="https://www.instagram.com/oneschoolofbusiness/"><img height="20px" width="20px" src="https://img.icons8.com/ios-glyphs/30/FFFFFF/instagram.png"
            alt="Instagram"></a>
      </div>
    </div>
    <div class="footer-column">
      <h3>USEFUL LINKS</h3>
      <ul style="display: flex;flex-direction: column;">
        <li><a href="/grievance">Grievance Cell</a></li>
        <li><a href="https://onesb.edu.in/assets/img/MD-2022.pdf" target="_blank">Mandatory Disclosures</a></li>
        <li><a href="/feedback">Feedback Facility</a></li>
        <li><a href="/aicte-approval">AICTE APPROVAL</a></li>
        <li><a href="/research">Research </a></li>
        <li><a href="/out-research-program">Out Reach Program</a></li>
        <li><a href="/infrasturture">Infrastructure</a></li>
        
        <li><a href="/alumini">Alumni</a></li>
        <li><a href="/commitee">Committee</a></li>
        <li><a href="/contact-us">Contact</a></li>
        
      </ul>
    </div>
    <div class="footer-column">
      <h3>LIBRARY RESOURCES</h3>
      <ul style="display: flex;flex-direction: column;">
        <li><a href="/library">Onesb E-Library</a></li>
        <li><a href="/sage-journals">Sage Journals</a></li>
        <li><a href="/mba/blogs">Blogs</a></li>
        <li><a href="/iic">IIC</a></li>
       
        <li><a href="/iqac">IQAC</a></li>
       
      </ul>
    </div>
  </div>
  <div class="footer-bottom mt-3">
    <p style="color: white;">&copy; 2024 Onesb Bangalore.</p>
  </div>
</footer>