<div class="main-header">
  <div class="logo">
    <img src="assets/images/ONESB-LOGO.webp" alt="ONESB">
  </div>
  <div class="contact-info-container">
    <div class="contact-item d-flex flex-column">
      <span class="label" style="font-size: 10px; font-weight: bolder;">EMAIL</span>
      <!-- <a href="mailto:admissions@onesb.edu.in" style="font-size: 15px; text-decoration: none; color: inherit;">
        admissions@onesb.edu.in
    </a> -->
      <a href="https://mail.google.com/mail/?view=cm&fs=1&to=admissions@onesb.edu.in" target="_blank"  style="color:#2b2d79; font-weight: bolder;">
        admissions@onesb.edu.in
    </a>
    
    
    </div>
    <span class="line-menu2"></span>
    <div class="contact-item d-flex flex-column">
      <span class="label" style="font-size: 10px; font-weight: bolder;">TOLL FREE</span>
<a href="tel:+919035065340" style="font-size: 15px; text-decoration: none; color: inherit;"  class="value" style="font-size: 15px; color:#2b2d79; font-weight: bolder;">
  +91 9035065340
</a>

    </div>
    <span class="line-menu2"></span>
    <div class="humberger">
      <div class="login-button">
        <a href="https://apply.onesb.edu.in/">Apply Now</a>
      </div>
      <div class="mobile-menu-icon" (click)="toggleMobileMenu()">☰</div>
    </div>

  </div>
</div>
<nav class="main-nav" [class.open]="mobileMenuOpen">
  <ul>
    <li *ngFor="let menuItem of MENUITEMS" class="dropdown" [ngClass]="{'has-submenu': menuItem.children}">
      <a [href]="menuItem.path || '#'" class="dropbtn">{{ menuItem.title }}</a>
      <div *ngIf="menuItem.children" class="dropdown-content">
        <ng-container *ngFor="let subItem of menuItem.children">
          <div *ngIf="subItem.children" class="dropdown-submenu" [ngClass]="{'has-submenu': subItem.children}">
            <a [href]="subItem.path || '#'" class="submenu-btn">{{ subItem.title }}</a>
            <div class="dropdown-content-submenu">
              <a *ngFor="let subSubItem of subItem.children" [href]="subSubItem.path">{{ subSubItem.title }}</a>
            </div>
          </div>
          <a *ngIf="!subItem.children" [href]="subItem.path">{{ subItem.title }}</a>
        </ng-container>
      </div>
    </li>
  </ul>
</nav>


