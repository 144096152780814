<div style="position: relative;">
  <div class="top-bar">
    <div class="social-icons">
      <a href="https://api.whatsapp.com/send?phone=8971080186"><img height="20px" width="20px" src="https://img.icons8.com/ios-glyphs/30/FFFFFF/whatsapp.png" alt="Whatsapp"></a>
      <a href="https://www.facebook.com/hk.bk.5492216?mibextid=ZbWKwL"><img height="20px" width="20px" src="https://img.icons8.com/ios-glyphs/30/FFFFFF/facebook.png" alt="Facebook"></a>
      <a href="https://www.instagram.com/oneschoolofbusiness?igsh=MXd0bXF6NHZncHR0Ng=="><img height="20px" width="20px" src="https://img.icons8.com/ios-glyphs/30/FFFFFF/instagram.png" alt="Instagram"></a>
    </div>
    <div class="user-options">
      <a href="contact-us">Contact-us</a>
    </div>
  </div>
</div>
<div class="">
  <div class="">
    <div class="">
      <div class="main-menu">
        <div class="menu-right pull-right w-100" style="width: 100% !important;">
          <div>
            <app-menu></app-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>